import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('../views/ContactView.vue')
  },
  {
    path: '/myrepair',
    name: 'my repair',
    component: () => import('../views/MyRepairView.vue')
  },
  // {
  //   path: '/pcrefresh',
  //   name: 'pc refresh',
  //   component: () => import('../views/PCRefreshView.vue')
  // },
  {
    path: '/services',
    name: 'services',
    component: () => import('../views/ServicesView.vue')
  },
  {
    path: '/software',
    name: 'software',
    component: () => import('../views/SoftwareView.vue')
  },
  {
    path: '/privacypolicy',
    name: 'privacypolicy',
    component: () => import('../views/PrivacyPolicyView.vue')
  },
  {
    path: '/termsofservice',
    name: 'termsofservice',
    component: () => import('../views/ToSView.vue')
  },
  {
    path: '/cancellationpolicy',
    name: 'cancellationpolicy',
    component: () => import('@/views/CancellationPolicyView.vue')
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
