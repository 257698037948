<template>
  <v-footer>
    <v-row justify="center" no-gutters>
      <v-btn link variant="flat" to = "/privacypolicy" text="Privacy Policy" />
      <v-btn link variant="flat" to = "/termsofservice" text="ToS" />
      <v-btn link variant="flat" to = "/cancellationpolicy" text="Cancellation Policy" />
      <v-col cols="12" justify="center">
        <div class="text-h6 text-center">Dan's Tech Services</div>
        <div class="text-subtitle-1 text-center">7740 River Rd</div>
        <div class="text-subtitle-1 text-center">Marine City, MI 48039</div>
        <div class="text-h6 text-center">810.328.3419</div>
        <div class="text-h6 text-center">dan@danstechservices.us</div>
      </v-col>
      <v-col></v-col>
      <v-col></v-col>
    </v-row>
  </v-footer>
</template>

<script>
export default {
  name: 'FooterComponent',
}
</script>