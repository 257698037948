<template>
  <v-app>
    <v-main>
      <AppBarComponent />
      <router-view/>
      <FooterComponent />
    </v-main>
  </v-app>
</template>

<script>
import AppBarComponent from './components/AppBarComponent.vue';
import FooterComponent from './components/FooterComponent.vue';

export default {
  name: 'App',
  components:{
    AppBarComponent,
    FooterComponent
  }
}
</script>

<style>
html {
  overflow: hidden !important;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
#app{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: thin;
}
</style>