<template>
  <v-app-bar>
    <template v-slot:prepend>
      <v-menu class="hidden-lg-and-up">
        <template v-slot:activator="{ props }">
          <v-app-bar-nav-icon v-bind="props" class="hidden-lg-and-up"/>
        </template>
        <v-list>
          <v-list-item link to="/" title="Home"></v-list-item>
          <!-- <v-list-item link to="/pcrefresh" title="PC Refresh" /> -->
          <v-list-item link to="/services" title="Services" />
          <v-list-item link to="/software" title="Software" />
          <!-- <v-list-item link to="/myrepair" title="My Repair" /> -->
          <!-- <v-list-item link to="/contact" title="Contact" /> -->
          <v-list-item link href = "https://app.danstechservices.us" target="_blank" title="Tech Login" />
        </v-list>
      </v-menu>
    </template>
    <v-app-bar-title>Dan's Tech Services</v-app-bar-title>

    <v-btn class="hidden-md-and-down" link to = "/" text="Home" />
    <!-- <v-btn class="hidden-md-and-down" link to = "/pcrefresh" text="PC Refresh" /> -->
    <v-btn class="hidden-md-and-down" link to = "/services" text="Services" />
    <v-btn class="hidden-md-and-down" link to = "/software" text="Software" />
    <!-- <v-btn class="hidden-md-and-down" link to = "/myrepair" text="My Repair" /> -->
    <!-- <v-btn class="hidden-md-and-down" link to = "/contact" text="Contact" /> -->
    <v-btn class="hidden-md-and-down" link href = "https://app.danstechservices.us" target="_blank" text="Tech Login" />
  </v-app-bar>
</template>

<script>
export default {
  name: 'AppBarComponent',
}
</script>