<template>
  <v-container>
    <v-card>
      <v-card-text class="text-h4">Fast, Reliable, Professional - Windows PC Repair and Other Tech Services</v-card-text>
      <v-card-text class="text-h6">Dedicated local tech support you can rely on as your one-stop shop</v-card-text>
      <v-card-text class="text-h6">Free, no obligation diagnostics - If we can't fix your problem we will let you know!</v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn link href="mailto:dan@danstechservices.us" text="dan@danstechservices.us" />
        <v-btn link href="tel:8103283419" text="810.328.3419" />
      </v-card-actions>
    </v-card>
  </v-container>

  <SupportRequestComponent />
  
  <v-container>
    <v-card>
      <iframe data-v-ccf6da5e="" data-v-e73e898e="" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2933.4000752926913!2d-82.5161848841593!3d42.67406697916693!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88256babec8463a7%3A0xefb3759748aa86f3!2sDan's%20Tech%20Services!5e0!3m2!1sen!2sus!4v1612310369003!5m2!1sen!2sus" allowfullscreen="allowfullscreen" aria-hidden="false" tabindex="0" width="100%" height="450" frameborder="0" style="border: 0px;"></iframe>
    </v-card>
  </v-container>
</template>

<script>
import SupportRequestComponent from '@/components/SupportRequestComponent.vue';

export default {
  name: 'HomeView',

  components:{
    SupportRequestComponent
  },

  data : function(){
    return{
    }
  },
  methods:{
    
  }
}
</script>