<template>
  <v-container>
    <v-card v-if="!requestsent">
      <v-card-text class="text-h4">Need Help Fast?</v-card-text>
      <v-card-text class="text-h6">Tell us a little more about the issue you are having:</v-card-text>
      
      <v-card-item>
        <v-text-field density="compact" placeholder="First Name" label="First Name" hint="Enter your first name here" v-model="request.firstname"/>
      </v-card-item>

      <v-card-item>
        <v-text-field density="compact" placeholder="Last Name" label="Last Name" hint="Enter your last name here" v-model="request.lastname"/>
      </v-card-item>

      <v-card-item>
        <v-text-field density="compact" placeholder="Phone Number" label="Phone Number" hint="Enter a good phone number to reach you with" v-model="request.phone"/>
      </v-card-item>

      <v-card-item>
        <v-text-field density="compact" placeholder="Email Address" label="Email Address" hint="Enter a good email address to send receipts to" v-model="request.email"/>
      </v-card-item>

      <v-card-item>
        <v-text-field density="compact" placeholder="Problem Description" label="Problem Description" maxlength="255" hint="Enter a brief explanation of the problem that you are having" v-model="request.description"/>
      </v-card-item>

      <v-card-actions>
        <v-spacer />
        <v-btn variant="elevated" @click="sendNewRequest" text="Send Support Request" />
      </v-card-actions>
    </v-card>
    <v-card v-else>
      <v-card-text class="text-h4">We are on it!</v-card-text>
      <v-card-text class="text-h6">We have received your support request and someone will contact you shortly.</v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: 'SupportRequestComponent',

  data : function(){
    return{
      request : {
        firstname : "",
        lastname : "",
        phone : "",
        email : "",
        description : "",
        techcompanyid : 1
      },
      requestsent : false
    }
  },
  methods:{
    sendNewRequest(){
      fetch("https://app.danstechservices.us:8900/api/newsupportrequest", { method: "POST", headers: { auth : JSON.stringify( this.auth ), data : JSON.stringify(this.request) }})
        .then(res => res.json())
        .then(res => {
          if(res.results != 'Failed'){
            this.requestsent = true;
          }
        });
    }
  }
}
</script>